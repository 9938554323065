import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p><em parentName="p">{`Note: The UI Shell does not currently use the Carbon theme tokens; theming options for the shell will be available in the future. All color used in the UI Shell is from the `}<a parentName="em" {...{
          "href": "https://www.ibm.com/design/language/elements/color#specifications"
        }}>{`IBM Design Langauge palette`}</a>{`.`}</em></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__navigation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`White`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu-chevron`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 70`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAABbElEQVQ4y62RsW7CMBRF2RGgEsQGCJgQM2sH93/6GZX6K/2IipmhqIhItBBgoaQSJSQRiCTg2/cMgVJI6NAnHfnJsY+vnUQymUz8e91QZTIZkc/nRaFQEJqmiVQqJdLp9FVyudwdKW5brdY9gEclrFQqolqtol6vo9FooFaroVgsolQqqTGOcrmMbDaLZrMJLiXs9/uCQK/Xk4ZhyNFoJIfDoWIwGMhutxuJruuy0+lgPp8H5POVkBrhOA5ms5mk/gzP864SBIE8JHx+tcTbu4F2+0WdOh6PMZ1OMZlMYJombNuOZbFYgKTHK398ecJ2XHyapiSB5EWr1QrL5VLB6V3XPSOc5/FEyFfebDZ8miSB3G63CIt73nBJGimk+wuObVkWyyQVQujbyeaopL7vH4VkF/xBmXY/ArsW4ORxsosJyS54IhSG6X4KozgTEg+0+Wn/ZHIvPQj5DXlxHOv1Wh0cCo+mveT3+Nfi9d9dEQmqN/SrpwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "UI shell side-nav example.",
            "title": "UI shell side-nav example.",
            "src": "/static/10210c4401dd6a09ddcc818fe3cfa35d/fb070/left-nav-style-1.png",
            "srcSet": ["/static/10210c4401dd6a09ddcc818fe3cfa35d/d6747/left-nav-style-1.png 288w", "/static/10210c4401dd6a09ddcc818fe3cfa35d/09548/left-nav-style-1.png 576w", "/static/10210c4401dd6a09ddcc818fe3cfa35d/fb070/left-nav-style-1.png 1152w", "/static/10210c4401dd6a09ddcc818fe3cfa35d/c3e47/left-nav-style-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">UI shell side-nav example.</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "link"
    }}>{`Link`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blue 60`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link--current`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link--current`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blue 60`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.46428571428571%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABqklEQVQoz7WTu07DMBSG2Vm4lavEMyAVVEEm2q2qBH2Drn0ALnv3LkBZEWtBrJTXACEBKyptSt0k5NrYOfx2HJSBESz9+n3+HH/HiZSZmX9ac9ASNA8tyLrdbm/e9x5K2C/m86Oj4/Ver2dgv5LPG43GBhFtKVq5XL6qVqtxrVYbVSoVViwWx61W66tzcT419nZZ/fCAVcr7bGe7OD49OXEuO53YMIxxvV5nsr9UKo2azWYE4JsC9vv9O8452bZNjuMo9zyPxrZP5sQn2+NkQcyJ6Mv1yPy0aDgcqj7LsogxRnEc0w9wMBh0JTCKoqnv+wIwEYZhwpwAikTISQQxCTeEh1ECYGKaIxGg13VdKS6EoCRJUiAm3VC61Bg8IIAp8D0KAl8/SigRHJmvMgyk3BLyDPSqgKDfaJACytvKVxZpk5I6hVuoPL3Nj1BzvX9RQGy6GjhNzwn52jFqoaeLXM5/yWP5DVE/Z8A7+oMFznsGvEY9hZvwCfwDE7vwJ9QO/FPnQ+S38Ecoy5nuf4CfKSDCORRr8FXtBZ3Pol6Hr+h8OcuhjVxeyP6Qb7UkZrhpDeayAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Link states",
        "title": "Link states",
        "src": "/static/7ea942bb0c8a9979b3cd164b42f8d46c/fb070/left-nav-style-4.png",
        "srcSet": ["/static/7ea942bb0c8a9979b3cd164b42f8d46c/d6747/left-nav-style-4.png 288w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/09548/left-nav-style-4.png 576w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/fb070/left-nav-style-4.png 1152w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/fb104/left-nav-style-4.png 1728w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/8fefe/left-nav-style-4.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Link states</Caption>
    <h3 {...{
      "id": "sub-menu"
    }}>{`Sub-menu`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 10-hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blue 60`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blue 60`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item--current`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item--current`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blue 60`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.46428571428571%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABqklEQVQoz7WTu07DMBSG2Vm4lavEMyAVVEEm2q2qBH2Drn0ALnv3LkBZEWtBrJTXACEBKyptSt0k5NrYOfx2HJSBESz9+n3+HH/HiZSZmX9ac9ASNA8tyLrdbm/e9x5K2C/m86Oj4/Ver2dgv5LPG43GBhFtKVq5XL6qVqtxrVYbVSoVViwWx61W66tzcT419nZZ/fCAVcr7bGe7OD49OXEuO53YMIxxvV5nsr9UKo2azWYE4JsC9vv9O8452bZNjuMo9zyPxrZP5sQn2+NkQcyJ6Mv1yPy0aDgcqj7LsogxRnEc0w9wMBh0JTCKoqnv+wIwEYZhwpwAikTISQQxCTeEh1ECYGKaIxGg13VdKS6EoCRJUiAm3VC61Bg8IIAp8D0KAl8/SigRHJmvMgyk3BLyDPSqgKDfaJACytvKVxZpk5I6hVuoPL3Nj1BzvX9RQGy6GjhNzwn52jFqoaeLXM5/yWP5DVE/Z8A7+oMFznsGvEY9hZvwCfwDE7vwJ9QO/FPnQ+S38Ecoy5nuf4CfKSDCORRr8FXtBZ3Pol6Hr+h8OcuhjVxeyP6Qb7UkZrhpDeayAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Sub-menu states",
        "title": "Sub-menu states",
        "src": "/static/7ea942bb0c8a9979b3cd164b42f8d46c/fb070/left-nav-style-4.png",
        "srcSet": ["/static/7ea942bb0c8a9979b3cd164b42f8d46c/d6747/left-nav-style-4.png 288w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/09548/left-nav-style-4.png 576w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/fb070/left-nav-style-4.png 1152w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/fb104/left-nav-style-4.png 1728w", "/static/7ea942bb0c8a9979b3cd164b42f8d46c/8fefe/left-nav-style-4.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Sub-menu states</Caption>
    <h3 {...{
      "id": "icon"
    }}>{`Icon`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__icon:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__icon:active`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__icon--current`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gray 100`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Menu labels and text should be set in sentence case.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiBold / 600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SemiBold / 600`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    {
      /*
      ### Text overflow
      [Guidance for text overflow and wrapping for platform name or labels]
      */
    }
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <p>{`The panel spans the full height of the browser and is fixed to the left edge of the window.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px/rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__navigation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256 / 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding left, padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__submenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding left, padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-07`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__menu-item`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`:before`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 / 0.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--side-nav__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "54.01785714285714%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACCklEQVQoz5WRzWsTQRTAI3pQJPUjbmlFFHW9VASpEPwrPOvRi0gR/Lr4AbV6sFQbMPHgTa2HVqRpRYmmDR4EL714sCIEqyh+7m6auN3NzszOznu+ye4e4iX44O3szvzm997OZHZt7sv0ilMjpzP/FRsHjdxWwyjsOWDe3T44UNy0pa9E05OVF5UaIp7UzNDQwd07DOOOaZqlfqO/mM1mNVOoURBzQjM0ru8IR67fPly4/xCny/M4M/cUp2bnsPRgCmXAEAFvaqby/Fl+vlzG6sICLtYWsfqyirMzjzHkgjx4STOKhxs6wkffLNPioUcLijLUaSMGmpRue0wz390g70tFFTBKGUwYiNQFzYBSsbBYX9n/2bIZCqomI1ChhE+tlt6gY1QzSx+/Hv3SWJV/7AYyrw2cCfjRbMmEuZj8ciycfvLq0GvLYR/aDJfXfHjr+bBkO6nwSgIPr7mu9H0fFQDoxjhjqfBcp0OAWHhvub7vHQmbTKDTZvCT8n1jNRVe1gyJjlCmgljIefp9tqvDid+/9lpCeMn5CKLFiueJBL6qGRGGwzKKAupCUupigjGWFj3fJXzjeyZgdzRlWjy+QcF5/h8EVRRhsu9Ml7Du2Dkm5RhN3KC8Rmc04XJ2nN53goJtmom8YACEHJUtfzx03HHFxC3g4TFicnS72USYPHqEEuG6XgwdQ2f8C7OWAgIS/GfOAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell side-nav",
        "title": "ui shell side-nav",
        "src": "/static/d2dc780ea0e965b4b944386b967c5d34/fb070/left-nav-style-2.png",
        "srcSet": ["/static/d2dc780ea0e965b4b944386b967c5d34/d6747/left-nav-style-2.png 288w", "/static/d2dc780ea0e965b4b944386b967c5d34/09548/left-nav-style-2.png 576w", "/static/d2dc780ea0e965b4b944386b967c5d34/fb070/left-nav-style-2.png 1152w", "/static/d2dc780ea0e965b4b944386b967c5d34/fb104/left-nav-style-2.png 1728w", "/static/d2dc780ea0e965b4b944386b967c5d34/8fefe/left-nav-style-2.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  Structure and spacing measurements for the side-nav | px | rem.
    </Caption>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "52.23214285714286%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAACHklEQVQoz4WRTWsTQRjHN+KlpgdbadWNRSUqptBSvOoH8OAHqFokEQt+A68ePXlrIgiCh1bUtlZLCWkhbbWIErxWqFGRgnWbZF+yu7NvM/P4PLPxYC8dWJ55+c1v/jOrnRoYzGjY+vSTZ4b10zP50cKTEzm90jdwvHKsv3+m0WjUAeA6MXoudzU3MvKsUCiUh4eGKtlstpzP55+aprmGzDlitIcLr5XwwePytUev5mF26R0srlThxfIKPH+zBCAEslAiZrVavf9pawtqtRpsvt+Eer0OG+vrkCQJMaNKuAxSCde2f1353PUDxgKOizF9hpARkTgxRcxeLO61aRwl4T8mpmUpAwlwWQlnI08J55o/J1YNI3YCRqmkx7ncsW0VT/SE20Z7ejfh4Jg2921Huj6T+44DUgg6OBUutlpHqL60zLENy0pYGl8GKG12u0qIp98kBneWaGzZFo/iWEqMFoYhcM4p8UUlfOs4SjhvmuMffZ+uoEIFUpKQ94STxODmuwnnEEURzSMhBWOMhHgtuJAmtO00oWmOffC8MEKKngmFyXfPg57wNjFCpAl770dSjnOQboFLStgMAiXcYWyiA/83Mwxb+D5fEb9BDPanDyD4V+JdlH7B7lkl/NFpq/qt1Rrcd7tFvNIULk5iLRmuO05rrucfpcq77Dz3wzvR704x/mMVhR/eAhbpyGdij6mfq9qeY2uHNRRlDmMwrfYXdRD32nsbT0UAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "ui shell side-nav with icons",
        "title": "ui shell side-nav with icons",
        "src": "/static/0195ae178eac004b094cfedbb9d57bc6/fb070/left-nav-style-3.png",
        "srcSet": ["/static/0195ae178eac004b094cfedbb9d57bc6/d6747/left-nav-style-3.png 288w", "/static/0195ae178eac004b094cfedbb9d57bc6/09548/left-nav-style-3.png 576w", "/static/0195ae178eac004b094cfedbb9d57bc6/fb070/left-nav-style-3.png 1152w", "/static/0195ae178eac004b094cfedbb9d57bc6/fb104/left-nav-style-3.png 1728w", "/static/0195ae178eac004b094cfedbb9d57bc6/8fefe/left-nav-style-3.png 2240w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  Structure and spacing measurements for side-nav with icons | px | rem.
    </Caption>
    {
      /*
      ### Responsive behavior
      */
    }
    {
      /*
      ### Icon placement
      [to do]
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      